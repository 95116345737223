import * as Yup from 'yup';

declare module 'yup' {
    interface StringSchema {
        castToEmptyStringIfNull(): StringSchema;
    }
}

Yup.addMethod(Yup.string, 'castToEmptyStringIfNull', function () {
    return this.transform((value: any) => {
        return !value ? '' : value;
    });
});

export default Yup;
