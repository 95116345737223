import { TextField, TextFieldProps } from '@mui/material';
import React, { RefObject } from 'react';
import { useField } from 'formik';
import { usePlacesWidget } from 'react-google-autocomplete';
import { googleMapsApiKey } from '../../environments.json';

type FormikGoogleAutocompleteProps = {
    onPlaceSelected: (places: google.maps.places.PlaceResult) => void;
    name: string;
    label: string;
} & TextFieldProps;

const FormikGoogleAutocomplete = ({ name, label, onPlaceSelected, ...rest }: FormikGoogleAutocompleteProps) => {
    const [field, meta] = useField(name);
    const { ref } = usePlacesWidget({
        onPlaceSelected: (places, ref, autocompleteRef) => {
            onPlaceSelected(places);
        },
        options: {
            types: ['address'],
        },
        apiKey: googleMapsApiKey,
    });
    return (
        <TextField
            {...field}
            label={label}
            error={!!meta.error && meta.touched}
            fullWidth
            helperText={meta.error && meta.touched ? meta.error : null}
            {...rest}
            inputRef={ref}
        />
    );
};

export default FormikGoogleAutocomplete;
