import {
    AvailableCloser,
    DispositionDto,
    GetLeadsQueryParams,
    ICreateAppointment,
    IUpdateAppointment,
    PaginatedQueryResponse,
    RescheduleAppointmentDto,
} from 'shared';
import { PostLeadAttachmentRequest } from 'shared/src/leads';
import { formQueryString, http } from '../http/http';
import { ActivityLog, Appointment, Attachment, CreateLeadDto, Lead, Note, UpdateLeadDto, User } from 'database';
import { ICreateLead, ICreateLeadByQr, PostLeadFromHomeownerFormResponse } from 'shared/src/leads/validation';

const ENDPOINT = 'leads';

export const getLeads = async (query: GetLeadsQueryParams): Promise<PaginatedQueryResponse<Lead>> => {
    const { data: response } = await http.get<PaginatedQueryResponse<Lead>>(`${ENDPOINT}`, {
        params: query,
    });
    return response;
};

export const createLead = async (lead: ICreateLead): Promise<Lead> => {
    const { data: response } = await http.post<Lead>(`${ENDPOINT}`, lead);
    return response;
};

export const getLeadById = async (id: string): Promise<Lead> => {
    const { data: response } = await http.get<Lead>(`${ENDPOINT}/${id}`);
    return response;
};

export type UpdateLeadArgs = {
    id: string;
    body: Partial<
        UpdateLeadDto & {
            setterId?: string;
            setterTeamId?: string;
            storeId: string;
        }
    >;
};

export const updateLead = async (args: UpdateLeadArgs): Promise<Lead> => {
    const { data: response } = await http.patch<Lead>(`${ENDPOINT}/${args.id}`, args.body);
    return response;
};

export const createInLightspeed = async (id: string): Promise<void> => {
    const { data: response } = await http.post(`${ENDPOINT}/${id}/createInLightspeed`);
    return response;
};

export type CreateAppointmentArgs = {
    id: string;
    body: ICreateAppointment;
};

export const createAppointment = async (args: CreateAppointmentArgs): Promise<void> => {
    const { data: response } = await http.post(`${ENDPOINT}/${args.id}/appointments`, args.body);
    return response;
};

export type GetAvailableClosersArgs = {
    id: string;
    appointmentId: string;
};

export const getAvailableClosers = async (args: GetAvailableClosersArgs): Promise<AvailableCloser[]> => {
    const { data: response } = await http.get<AvailableCloser[]>(
        `${ENDPOINT}/${args.id}/appointments/${args.appointmentId}/availableClosers`,
    );
    return response;
};

export type UpdateAppointmentCloserArgs = {
    id: string;
    appointmentId: string;
    body: { closerId: number };
};

export const updateAppointmentCloser = async (args: UpdateAppointmentCloserArgs): Promise<void> => {
    const { data: response } = await http.post(
        `${ENDPOINT}/${args.id}/appointments/${args.appointmentId}/closer`,
        args.body,
    );
    return response;
};

export type UpdateCurrentAppointmentArgs = {
    id: string;
    appointmentId: string;
    body: IUpdateAppointment;
};

export const updateCurrentAppointment = async (args: UpdateCurrentAppointmentArgs): Promise<Appointment> => {
    const { data: response } = await http.put(`${ENDPOINT}/${args.id}/appointments/current`, args.body);
    return response;
};

export type DispositionArgs = {
    id: string;
    appointmentId: string;
    body: DispositionDto;
};

export const setAppointmentDisposition = async (args: DispositionArgs): Promise<Appointment> => {
    const { data: response } = await http.post(
        `${ENDPOINT}/${args.id}/appointments/${args.appointmentId}/disposition`,
        args.body,
    );
    return response;
};

export type CreateNoteArgs = {
    id: string;
    body: string;
};

export const createNote = async (args: CreateNoteArgs): Promise<Note> => {
    const { data: response } = await http.post(`${ENDPOINT}/${args.id}/notes`, {
        body: args.body,
    });
    return response;
};

export type GetNotesArgs = {
    id: string;
};

export const getNotes = async (args: GetNotesArgs): Promise<Note[]> => {
    const { data: response } = await http.get<Note[]>(`${ENDPOINT}/${args.id}/notes`);
    return response;
};

export type PostNoteArgs = {
    id: string;
    body: {
        note: string;
    };
};

export const postNote = async (args: PostNoteArgs): Promise<Note> => {
    const { data: response } = await http.post(`${ENDPOINT}/${args.id}/notes`, args.body);
    return response;
};

export const getActivityLogs = async (id: string): Promise<ActivityLog[]> => {
    const { data: response } = await http.get<ActivityLog[]>(`${ENDPOINT}/${id}/activityLogs`);
    return response;
};

export const getAttachments = async (id: string): Promise<Attachment[]> => {
    const { data: response } = await http.get<Attachment[]>(`${ENDPOINT}/${id}/attachments`);
    return response;
};

export interface PostAttachmentArgs {
    id: string;
    body: PostLeadAttachmentRequest;
}

export const postAttachment = async ({ id, body }: PostAttachmentArgs): Promise<string> => {
    const { data: response } = await http.post<string>(`${ENDPOINT}/${id}/attachments`, body);
    return response;
};

export interface DeleteAttachmentArgs {
    id: string;
    attachmentId: string;
}

export const deleteAttachment = async ({ id, attachmentId }: DeleteAttachmentArgs): Promise<void> => {
    await http.delete(`${ENDPOINT}/${id}/attachments/${attachmentId}`);
};

export type DeleteNoteArgs = {
    id: string;
    noteId: string;
};

export const deleteNote = async (args: DeleteNoteArgs): Promise<Note> => {
    const { data: response } = await http.delete(`${ENDPOINT}/${args.id}/notes/${args.noteId}`);
    return response;
};

export const getMyAppointments = async (args: { startDate: Date; endDate: Date }): Promise<Appointment[]> => {
    const { data: response } = await http.get<Appointment[]>(`${ENDPOINT}/my-appointments`, {
        params: args,
    });
    return response;
};

export const deleteLead = async (id: string): Promise<void> => {
    await http.delete(`${ENDPOINT}/${id}`);
};

type PostCallCenterDispositionArgs = {
    id: string;
    dispositionId: number;
};

export const postCallCenterDisposition = async ({
    id,
    dispositionId,
}: PostCallCenterDispositionArgs): Promise<Lead> => {
    const { data: response } = await http.post<Lead>(`${ENDPOINT}/${id}/callCenterDisposition`, {
        dispositionId,
    });
    return response;
};

export const getLeadGenQrAvailability = async (userId: string): Promise<boolean> => {
    const { data: response } = await http.get<boolean>(`${ENDPOINT}/getLeadGenQrAvailability/${userId}`);
    return response;
};

export type GetUtilitiesArgs = {
    city: string;
    zip: string;
};

export const getUtilities = async ({ city, zip }: GetUtilitiesArgs): Promise<any> => {
    const { data: response } = await http.get<any>(`${ENDPOINT}/getUtilities/${city}/${zip}`);
    return response;
};

export const postLeadFromHomeownerForm = async (body: ICreateLeadByQr): Promise<PostLeadFromHomeownerFormResponse> => {
    const { data: response } = await http.post<PostLeadFromHomeownerFormResponse>(
        `${ENDPOINT}/postLeadFormHomeownerForm`,
        body,
    );
    return response;
};
