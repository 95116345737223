import Yup from '../../yup';

export const CreateLeadByQrSchema = Yup.object({
    rentOrOwn: Yup.string().required('This field is required.'),
    preferredLanguage: Yup.string().required('This field is required.'),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    email: Yup.string().matches(
        /^(|[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+)$/,
        'Email must be empty or a valid email address.',
    ),
    phone: Yup.string().matches(/^(\d{10}|)$/, 'Phone must be empty or a valid phone number.'), // TODO: update to phone regex
    streetAddress1: Yup.string().required('Street Address 1 is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().required('Zip is required.'),
    utilityCompany: Yup.string().required('Utility Company is required.'),
    averageMonthlyUtilityBill: Yup.string().required('Average Monthly Utility Bill is required.'),
    approximateCreditScore: Yup.string().nullable().castToEmptyStringIfNull(),
    ecpaDisclosure: Yup.boolean().required('ECPA Disclosure is required.'),
    // update interface below to match Schema to expose to TSOA
});

// hard interface version of the schema naming cause CreateLeadDto is already taken
export interface ICreateLeadByQr {
    rentOrOwn: string;
    preferredLanguage: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    streetAddress1: string;
    city: string;
    state: string;
    zip: string;
    utilityCompany: string;
    averageMonthlyUtilityBill: string;
    approximateCreditScore: string;
    ecpaDisclosure: boolean;
    setterId: number;
    setterTeamId: number;
    storeId: string;
}

export type PostLeadFromHomeownerFormResponse = {
    isNewLead: boolean;
    leadId: string;
};
