import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const FormikDebugger = () => {
    const { values } = useFormikContext();
    useEffect(() => {
        console.log(values);
    }, [values]);
    return null;
};

export default FormikDebugger;
