import React from 'react';
import { useField, useFormikContext } from 'formik';
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { BOTTOM_NAV_Z_INDEX } from '../../styles/styles';

type FormikVitlSelectProps = {
    name: string;
    label: string;
    options: {
        value: string;
        label: string;
    }[];
    onSelectChange?: (event: SelectChangeEvent<string>, child?: React.ReactNode) => void;
} & FormControlProps;

const FormikVitlSelect = ({ name, label, options, onSelectChange, ...rest }: FormikVitlSelectProps) => {
    const [field, meta, helpers] = useField(name);
    const { handleChange } = useFormikContext();
    const checkForOnChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        if (onSelectChange) {
            onSelectChange(event);
        }
        handleChange(event);
    };
    return (
        <FormControl fullWidth error={!!meta.error} {...rest}>
            <InputLabel>{label}</InputLabel>
            <Select
                {...field}
                label={label}
                value={meta.value ?? ''}
                onChange={checkForOnChange}
                MenuProps={{
                    style: {
                        zIndex: BOTTOM_NAV_Z_INDEX + 2,
                    },
                }}
            >
                {options.map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                        {o.label}
                    </MenuItem>
                ))}
            </Select>
            {!!meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FormikVitlSelect;
